import {
  X12,
  addAmounts,
  divideByNumber,
  divideByRate,
  makeAmountDivisibleByRate,
  multiplyByNumber,
  multiplyByRate,
  newAmount,
  newFinancialRate,
  zeroAmount,
} from '@orus.eu/amount'

/**
 * The premium data used to compute the final premium
 */
export type PremiumData = {
  /** The ex-tax premium including commission */
  exTax: number
  /** The tax rate to apply to {@link PremiumData.exTax} */
  taxRate: number
  /** The commission rate to apply to {@link PremiumData.exTax} */
  commissionRate: number
}

import type { Amount } from '@orus.eu/amount'
import type { PaymentRecurrence } from './payment-recurrence.js'

export type MonthlyPremium = {
  type: 'monthly'

  /** The monthly premium excluding taxes */
  exTax: Amount
  /** The annualized premium excluding taxes ({@link MonthlyPremium.exTax} * 12) */
  annualizedExTax: Amount

  /** The monthly premium taxes */
  tax: Amount
  /** The annualized premium taxes ({@link MonthlyPremium.tax} * 12) */
  annualizedTax: Amount

  /** The monthly total premium including taxes */
  total: Amount
  /** The annualized total premium including taxes ({@link MonthlyPremium.total} * 12) */
  annualizedTotal: Amount

  /** The monthly commission amount for this premium */
  commission: Amount
  /** The annualized commission amount for this premium ({@link MonthlyPremium.commission} * 12) */
  annualizedCommission: Amount
}

export function addMonthlyPremiums(premiums: (MonthlyPremium | undefined)[]): MonthlyPremium {
  return premiums.reduce<MonthlyPremium>(
    (acc, premium) => {
      return premium
        ? {
            type: 'monthly',
            exTax: addAmounts(acc.exTax, premium.exTax),
            annualizedExTax: addAmounts(acc.annualizedExTax, premium.annualizedExTax),
            tax: addAmounts(acc.tax, premium.tax),
            annualizedTax: addAmounts(acc.annualizedTax, premium.annualizedTax),
            total: addAmounts(acc.total, premium.total),
            annualizedTotal: addAmounts(acc.annualizedTotal, premium.annualizedTotal),
            commission: addAmounts(acc.commission, premium.commission),
            annualizedCommission: addAmounts(acc.annualizedCommission, premium.annualizedCommission),
          }
        : acc
    },
    {
      type: 'monthly',
      exTax: zeroAmount,
      annualizedExTax: zeroAmount,
      tax: zeroAmount,
      annualizedTax: zeroAmount,
      total: zeroAmount,
      annualizedTotal: zeroAmount,
      commission: zeroAmount,
      annualizedCommission: zeroAmount,
    },
  )
}

export type TotalMonthlyPremium = Omit<MonthlyPremium, 'type' | 'total'> & {
  type: 'totalMonthly'

  /** The annualized total premium including taxes, yearly taxes and Orus/partner fees ({@link MonthlyPremium.total} * 11 + {@link TotalMonthlyPremium.firstMonthTotal}) */
  annualizedTotalWithYearlyTaxes: Amount

  /** The amount for the first month of every contract year ({@link TotalMonthlyPremium.subsequentMonthsTotal} + sum of {@link QuoteV2.yearlyTaxes} + {@link MonthlyPremium.monthlyFees} + Orus/partner fees) */
  firstMonthTotal: Amount

  /** The amount for the subsequent months including monthly fees and Orus/partner fees if any */
  subsequentMonthsTotal: Amount
}

export type YearlyPremium = {
  type: 'yearly'

  /** The yearly premium excluding taxes */
  exTax: Amount
  /** The monthlyized premium excluding taxes ({@link YearlyPremium.exTax} / 12) */
  monthlyizedExTax: Amount

  /** The yearly premium taxes (excluding yearly taxes like terrorism tax) */
  tax: Amount
  /** The monthlyized premium taxes ({@link YearlyPremium.tax} / 12) */
  monthlyizedTax: Amount

  /** The yearly total premium including taxes, yearly taxes (like terrorism tax) and Orus/partner fees */
  total: Amount
  /** The monthlyized total premium including taxes, yearly taxes and Orus/partner fees ({@link YearlyPremium.total} / 12) */
  monthlyizedTotal: Amount

  /** The yearly commission amount for this premium */
  commission: Amount
  /** The monthlyized commission amount for this premium ({@link YearlyPremium.commission} / 12) */
  monthlyizedCommission: Amount
}

export function addYearlyPremiums(premiums: (YearlyPremium | undefined)[]): YearlyPremium {
  return premiums.reduce<YearlyPremium>(
    (acc, premium) => {
      return premium
        ? {
            type: 'yearly',
            exTax: addAmounts(acc.exTax, premium.exTax),
            monthlyizedExTax: addAmounts(acc.monthlyizedExTax, premium.monthlyizedExTax),
            tax: addAmounts(acc.tax, premium.tax),
            monthlyizedTax: addAmounts(acc.monthlyizedTax, premium.monthlyizedTax),
            total: addAmounts(acc.total, premium.total),
            monthlyizedTotal: addAmounts(acc.monthlyizedTotal, premium.monthlyizedTotal),
            commission: addAmounts(acc.commission, premium.commission),
            monthlyizedCommission: addAmounts(acc.monthlyizedCommission, premium.monthlyizedCommission),
          }
        : acc
    },
    {
      type: 'yearly',
      exTax: zeroAmount,
      monthlyizedExTax: zeroAmount,
      tax: zeroAmount,
      monthlyizedTax: zeroAmount,
      total: zeroAmount,
      monthlyizedTotal: zeroAmount,
      commission: zeroAmount,
      monthlyizedCommission: zeroAmount,
    },
  )
}

export type OneTimePremium = {
  type: 'oneTime'

  /** The premium amount excluding taxes */
  exTax: Amount

  /** The premium taxes */
  tax: Amount

  /** The total premium including taxes */
  total: Amount

  /** The commission amount for this premium */
  commission: Amount
}

export type PremiumPaymentRecurrence = PaymentRecurrence | 'oneTime'

export type PremiumForPaymentRecurrence<WantedPaymentRecurrence extends PremiumPaymentRecurrence> =
  WantedPaymentRecurrence extends 'monthly'
    ? MonthlyPremium
    : WantedPaymentRecurrence extends 'yearly'
      ? YearlyPremium
      : OneTimePremium

export type TotalPremiumForPaymentRecurrence<WantedPaymentRecurrence extends PremiumPaymentRecurrence> =
  WantedPaymentRecurrence extends 'monthly'
    ? TotalMonthlyPremium
    : WantedPaymentRecurrence extends 'yearly'
      ? YearlyPremium
      : OneTimePremium

export type YearlyAndMonthlyPremium = { yearly: YearlyPremium; monthly: MonthlyPremium }

export function computeYearlyAndMonthlyPremiumFromData(data: PremiumData): YearlyAndMonthlyPremium {
  const { exTax, taxRate, commissionRate } = data

  const exTaxAmount = newAmount(exTax)
  const taxRateRate = newFinancialRate(taxRate)
  const commissionRateRate = newFinancialRate(commissionRate)

  const annualizedExTax = makeAmountDivisibleByRate(exTaxAmount, X12)
  const taxAmount = multiplyByRate(annualizedExTax, taxRateRate)
  const annualizedTax = makeAmountDivisibleByRate(taxAmount, X12)
  // Note that unless exTax and tax, commission is not necessarily divisible by 12
  // because it's not client facing, hence no issue if monthly * 12 not exactly equal to yearly
  const annualizedCommission = multiplyByRate(annualizedExTax, commissionRateRate)
  const annualizedTotal = addAmounts(annualizedExTax, annualizedTax)

  const monthlyizedExTax = divideByRate(annualizedExTax, X12)
  const monthlyizedTax = divideByRate(annualizedTax, X12)
  const monthlyizedCommission = divideByRate(annualizedCommission, X12)
  const monthlyizedTotal = divideByRate(annualizedTotal, X12)

  return {
    yearly: {
      type: 'yearly',

      exTax: annualizedExTax,
      monthlyizedExTax,

      tax: annualizedTax,
      monthlyizedTax,

      total: annualizedTotal,
      monthlyizedTotal,

      commission: annualizedCommission,
      monthlyizedCommission,
    },
    monthly: {
      type: 'monthly',

      exTax: monthlyizedExTax,
      annualizedExTax,

      tax: monthlyizedTax,
      annualizedTax,

      total: monthlyizedTotal,
      annualizedTotal,

      commission: monthlyizedCommission,
      annualizedCommission,
    },
  }
}
export function computeOneTimePremiumFromData(data: PremiumData): OneTimePremium {
  const { exTax, taxRate, commissionRate } = data

  const exTaxAmount = newAmount(exTax)
  const taxRateRate = newFinancialRate(taxRate)
  const commissionRateRate = newFinancialRate(commissionRate)
  const taxAmount = multiplyByRate(exTaxAmount, taxRateRate)
  const totalAmount = addAmounts(exTaxAmount, taxAmount)
  const commission = multiplyByRate(exTaxAmount, commissionRateRate)

  return {
    type: 'oneTime',
    exTax: exTaxAmount,
    tax: taxAmount,
    total: totalAmount,
    commission: commission,
  }
}

export function sumYearlyPremiums(yearlyPremiums: YearlyPremium[], yearlyTaxesSum: Amount): YearlyPremium {
  const reduced = yearlyPremiums.reduce(
    (acc, yearlyPremium) => ({
      ...acc,
      exTax: addAmounts(acc.exTax, yearlyPremium.exTax),
      monthlyizedExTax: addAmounts(acc.monthlyizedExTax, yearlyPremium.monthlyizedExTax),
      tax: addAmounts(acc.tax, yearlyPremium.tax),
      monthlyizedTax: addAmounts(acc.monthlyizedTax, yearlyPremium.monthlyizedTax),
      total: addAmounts(acc.total, yearlyPremium.total),
      commission: addAmounts(acc.commission, yearlyPremium.commission),
      monthlyizedCommission: addAmounts(acc.monthlyizedCommission, yearlyPremium.monthlyizedCommission),
    }),
    {
      type: 'yearly' as const,
      exTax: zeroAmount,
      monthlyizedExTax: zeroAmount,
      tax: zeroAmount,
      monthlyizedTax: zeroAmount,
      total: yearlyTaxesSum,
      commission: zeroAmount,
      monthlyizedCommission: zeroAmount,
    },
  )

  return {
    ...reduced,
    monthlyizedTotal: divideByRate(reduced.total, X12),
  }
}

export function sumMonthlyPremiums(
  monthlyPremiums: MonthlyPremium[],
  yearlyTaxesSum: Amount,
  monthlyFees: Amount,
): TotalMonthlyPremium {
  return monthlyPremiums.reduce<TotalMonthlyPremium>(
    (acc, monthlyPremium) => ({
      ...acc,
      exTax: addAmounts(acc.exTax, monthlyPremium.exTax),
      annualizedExTax: addAmounts(acc.annualizedExTax, monthlyPremium.annualizedExTax),
      tax: addAmounts(acc.tax, monthlyPremium.tax),
      annualizedTax: addAmounts(acc.annualizedTax, monthlyPremium.annualizedTax),
      annualizedTotal: addAmounts(acc.annualizedTotal, monthlyPremium.annualizedTotal),
      annualizedTotalWithYearlyTaxes: addAmounts(acc.annualizedTotalWithYearlyTaxes, monthlyPremium.annualizedTotal),
      firstMonthTotal: addAmounts(acc.firstMonthTotal, monthlyPremium.total),
      subsequentMonthsTotal: addAmounts(acc.subsequentMonthsTotal, monthlyPremium.total),
      commission: addAmounts(acc.commission, monthlyPremium.commission),
      annualizedCommission: addAmounts(acc.annualizedCommission, monthlyPremium.annualizedCommission),
    }),
    {
      type: 'totalMonthly',
      exTax: zeroAmount,
      annualizedExTax: zeroAmount,
      tax: zeroAmount,
      annualizedTax: zeroAmount,
      annualizedTotal: zeroAmount,
      annualizedTotalWithYearlyTaxes: yearlyTaxesSum,
      firstMonthTotal: addAmounts(yearlyTaxesSum, divideByNumber(monthlyFees, 12)),
      subsequentMonthsTotal: divideByNumber(monthlyFees, 12),
      commission: zeroAmount,
      annualizedCommission: zeroAmount,
    },
  )
}

export function sumOneTimePremiums(oneTimePremiums: OneTimePremium[]): OneTimePremium {
  return oneTimePremiums.reduce<OneTimePremium>(
    (acc, oneTimePremium) => ({
      ...acc,
      exTax: addAmounts(acc.exTax, oneTimePremium.exTax),
      tax: addAmounts(acc.tax, oneTimePremium.tax),
      commission: addAmounts(acc.commission, oneTimePremium.commission),
      total: addAmounts(acc.total, oneTimePremium.total),
    }),
    {
      type: 'oneTime',
      exTax: zeroAmount,
      tax: zeroAmount,
      commission: zeroAmount,
      total: zeroAmount,
    },
  )
}

/**
 * Calculates the effective yearly premium including fees and taxes based on the given premium.
 *
 * @param {MonthlyPremium | TotalMonthlyPremium | YearlyPremium} [premium] - The premium to calculate the effective yearly premium from.
 * @returns {{ exTax: Amount, tax: Amount, total: Amount, commission: Amount }} An object containing the effective yearly premium details.
 */
export function getEffectiveYearlyPremiumWithExtraFees(
  premium?: MonthlyPremium | TotalMonthlyPremium | YearlyPremium,
): {
  exTax: Amount
  tax: Amount
  total: Amount
  commission: Amount
} {
  if (premium && premium.type === 'totalMonthly') {
    return {
      exTax: premium.exTax,
      tax: premium.tax,
      total: getCummulatedTotalMonthlyPremiumPerYear(premium),
      commission: premium.commission,
    }
  } else {
    return getEffectiveYearlyPremium(premium)
  }
}

/**
 * Calculates the effective yearly premium based on the given premium. However, it does not include the extra fees for the
 * 'totalMonthly' case. For that, use the {@link getEffectiveYearlyPremiumWithExtraFees()} method.
 *
 * @param {MonthlyPremium | TotalMonthlyPremium | YearlyPremium} [premium] - The premium to calculate the effective yearly premium from.
 * @returns {{ exTax: Amount, tax: Amount, total: Amount, commission: Amount }} An object containing the effective yearly premium details.
 */
export function getEffectiveYearlyPremium(premium?: MonthlyPremium | TotalMonthlyPremium | YearlyPremium): {
  exTax: Amount
  tax: Amount
  total: Amount
  commission: Amount
} {
  if (!premium) {
    return {
      exTax: zeroAmount,
      tax: zeroAmount,
      total: zeroAmount,
      commission: zeroAmount,
    }
  }
  if (premium.type === 'yearly') {
    return {
      exTax: premium.exTax,
      tax: premium.tax,
      total: premium.total,
      commission: premium.commission,
    }
  } else {
    return {
      exTax: premium.annualizedExTax,
      tax: premium.annualizedTax,
      total: premium.annualizedTotal,
      commission: premium.annualizedCommission,
    }
  }
}

export function getPricingForRelevantPremium(premium?: TotalMonthlyPremium | YearlyPremium): {
  exTax: Amount
  tax: Amount
  total: Amount
  commission: Amount
} {
  if (!premium) {
    return {
      exTax: zeroAmount,
      tax: zeroAmount,
      total: zeroAmount,
      commission: zeroAmount,
    }
  }
  if (premium.type === 'yearly') {
    return {
      exTax: premium.exTax,
      tax: premium.tax,
      total: premium.total,
      commission: premium.commission,
    }
  } else {
    return {
      exTax: premium.exTax,
      tax: premium.tax,
      total: premium.subsequentMonthsTotal,
      commission: premium.commission,
    }
  }
}

/**
 * Returns the total yearly premium of the monthly payments described by a quote, including taxes and fees.
 *
 * @param {TotalMonthlyPremium} premium - The total monthly premium to calculate the yearly premium from.
 * @returns {Amount} The total yearly premium.
 */
export function getCummulatedTotalMonthlyPremiumPerYear(premium: TotalMonthlyPremium): Amount {
  return addAmounts(premium.firstMonthTotal, multiplyByNumber(premium.subsequentMonthsTotal, 11))
}
