import { addAmounts, zeroAmount, type Amount } from '@orus.eu/amount'
import type {
  BackofficeContractDescription,
  BackofficeContractVersionDescription,
} from '@orus.eu/backend/src/views/backoffice-contract-view'
import { getSelectedProducts, productAmountFactories, type AggregatedQuote } from '@orus.eu/dimensions'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import type { ProtectionStatusSchedule, UpdatedProtectionStatus } from '@orus.eu/protection'
import type { OrganizationType } from '@orus.eu/right-access-management'
import { translate, type Language } from '@orus.eu/translations'
import deepEqual from 'deep-equal'
import { formatTimestamp } from './format'
import type { IconLabelColor } from './icon-label'

const commentsPerUpdateStatus: {
  [Status in UpdatedProtectionStatus]: (timestamp: number) => string
} = {
  suspended: (timestamp) => `Suspendu le ${formatTimestamp(timestamp)}`,
  terminated: (timestamp) => `Résilié le ${formatTimestamp(timestamp)}`,
  active: (timestamp) => `Réactivé le ${formatTimestamp(timestamp)}`,
}

function getStatusComment(scheduledUpdates: ProtectionStatusSchedule['scheduledStatusUpdates'] | null): string | null {
  if (scheduledUpdates === null) {
    return null
  }

  if (scheduledUpdates.suspended.scheduledAtTimestamp !== null) {
    return commentsPerUpdateStatus.suspended(scheduledUpdates.suspended.scheduledAtTimestamp)
  }

  if (scheduledUpdates.terminated.scheduledAtTimestamp !== null) {
    return commentsPerUpdateStatus.terminated(scheduledUpdates.terminated.scheduledAtTimestamp)
  }

  if (scheduledUpdates.active.scheduledAtTimestamp !== null) {
    return commentsPerUpdateStatus.active(scheduledUpdates.active.scheduledAtTimestamp)
  }

  return null
}

type ContractStatusUiProps = IconLabelColor & {
  comment?: string
  timestamp?: number
}

export function getContractStatusUiProps(protectionStatusSchedule: ProtectionStatusSchedule): ContractStatusUiProps {
  switch (protectionStatusSchedule.currentStatus) {
    case 'active': {
      return {
        icon: 'circle-check-regular',
        label: 'Actif',
        variant: 'success',
        comment: getStatusComment(protectionStatusSchedule.scheduledStatusUpdates) ?? undefined,
      }
    }
    case 'not-started':
      return {
        icon: 'hourglass-regular',
        variant: 'informal',
        label: 'Prochainement actif',
      }
    case 'suspended':
      return {
        icon: 'lock-regular',
        variant: 'warning',
        label: 'Suspendu',
        comment: getStatusComment(protectionStatusSchedule.scheduledStatusUpdates) ?? undefined,
      }
    case 'terminated':
      return {
        icon: 'ban-regular',
        variant: 'danger',
        label: 'Résilié',
        comment: getStatusComment(protectionStatusSchedule.scheduledStatusUpdates) ?? undefined,
        timestamp: protectionStatusSchedule.currentStatusCause?.fromTimestamp,
      }
  }
}

export type ContractDocumentType = 'agreed-terms' | 'agreed-terms-draft' | 'payment-schedule'

export const dynamicFileLabels: { [key in ContractDocumentType]: (language: Language) => string } = {
  'agreed-terms': (language) => `Orus - ${translate('filename_agreed_terms', language)}.pdf`,
  'agreed-terms-draft': (language) => `Orus - ${translate('filename_agreed_terms_draft', language)}.pdf`,
  'payment-schedule': (language) => `Orus - ${translate('filename_payments_schedule', language)}.pdf`,
}

export type ContractDocumentDescription =
  | { method: 'get'; fileName: string; url: string }
  | { method: 'post'; fileName: string; onClick: () => Promise<void> }

type QuoteAmounts = {
  yearlyBasePremium: Amount
  yearlyOrusFee: Amount
  yearlyTaxes: Amount
  yearlyTotalPremium: Amount
  monthlyBasePremium: Amount
  monthlyOrusFee: Amount
  monthlyTaxes: Amount
  subsequentMonthsTotalPremium: Amount
  firstMonthTotalPremium: Amount
}

function addInvoiceAmounts(amounts1: QuoteAmounts, amounts2: QuoteAmounts): QuoteAmounts {
  return {
    yearlyBasePremium: addAmounts(amounts1.yearlyBasePremium, amounts2.yearlyBasePremium),
    yearlyOrusFee: addAmounts(amounts1.yearlyOrusFee, amounts2.yearlyOrusFee),
    yearlyTaxes: addAmounts(amounts1.yearlyTaxes, amounts2.yearlyTaxes),
    yearlyTotalPremium: addAmounts(amounts1.yearlyTotalPremium, amounts2.yearlyTotalPremium),
    monthlyBasePremium: addAmounts(amounts1.monthlyBasePremium, amounts2.monthlyBasePremium),
    monthlyOrusFee: addAmounts(amounts1.monthlyOrusFee, amounts2.monthlyOrusFee),
    monthlyTaxes: addAmounts(amounts1.monthlyTaxes, amounts2.monthlyTaxes),
    subsequentMonthsTotalPremium: addAmounts(
      amounts1.subsequentMonthsTotalPremium,
      amounts2.subsequentMonthsTotalPremium,
    ),
    firstMonthTotalPremium: addAmounts(amounts1.firstMonthTotalPremium, amounts2.firstMonthTotalPremium),
  }
}

function buildQuoteInvoice(contractVersion: BackofficeContractVersionDescription, organizationType: OrganizationType) {
  const input = contractVersion.dimensions
  const isInFirstYear = contractVersion.expectedYearlyPremiums.isInFirstYear

  let quoteAmounts = {
    yearlyBasePremium: zeroAmount,
    yearlyOrusFee: zeroAmount,
    yearlyTaxes: zeroAmount,
    yearlyTotalPremium: zeroAmount,
    monthlyBasePremium: zeroAmount,
    monthlyOrusFee: zeroAmount,
    monthlyTaxes: zeroAmount,
    subsequentMonthsTotalPremium: zeroAmount,
    firstMonthTotalPremium: zeroAmount,
  }

  for (const product of getSelectedProducts(input)) {
    /**
     * We want prices taking discount and terrorism tax into account if any i.e. for the first period of the year.
     */
    const productFirstMonthInvoicingAmounts = productAmountFactories[product].monthly({
      input,
      isInFirstYear,
      isFirstOfYear: true,
      isFirstEver: isInFirstYear,
      organizationType,
    }).final

    /**
     * We want prices when it's not the first period of the year.
     */
    const productMonthlyInvoicingAmounts = productAmountFactories[product].monthly({
      input,
      isInFirstYear,
      isFirstOfYear: false,
      isFirstEver: false,
      organizationType,
    }).final

    /**
     * We want prices taking discount and terrorism tax into account if any.
     */
    const productYearlyInvoicingAmounts = productAmountFactories[product].yearly({
      input,
      isInFirstYear,
      isFirstOfYear: true,
      isFirstEver: isInFirstYear,
      organizationType,
    }).final

    const productInvoiceAmounts = {
      yearlyBasePremium: productYearlyInvoicingAmounts.premiumWithoutTaxes,
      yearlyOrusFee: productYearlyInvoicingAmounts.orusFee,
      yearlyTaxes: productYearlyInvoicingAmounts.insuranceTaxes,
      yearlyTotalPremium: productYearlyInvoicingAmounts.totalPremium,
      monthlyBasePremium: productMonthlyInvoicingAmounts.premiumWithoutTaxes,
      monthlyOrusFee: productMonthlyInvoicingAmounts.orusFee,
      monthlyTaxes: productMonthlyInvoicingAmounts.insuranceTaxes,
      subsequentMonthsTotalPremium: productMonthlyInvoicingAmounts.totalPremium,
      firstMonthTotalPremium: productFirstMonthInvoicingAmounts.totalPremium,
    }

    quoteAmounts = addInvoiceAmounts(quoteAmounts, productInvoiceAmounts)
  }

  return { ...checkDefinedAndNotNull(input.quote), ...quoteAmounts }
}

export type TimeRangeQuote = {
  id: string
  /**
   * When the quote becomes applicable, included. Null if this is the first quote.
   */
  startTimestamp: number | null
  /**
   * When the quote stops being applicable, excluded. Null if this is the last quote.
   */
  endTimestamp: number | null
  quote: AggregatedQuote
}

export function getApplicableQuotes(
  contract: BackofficeContractDescription,
  organizationType: OrganizationType,
): TimeRangeQuote[] {
  const applicableQuotes: TimeRangeQuote[] = [
    {
      id: '0',
      startTimestamp: null,
      endTimestamp: null,
      quote: buildQuoteInvoice(contract.versions[0], organizationType),
    },
  ]
  for (let i = 1 /*first quote already taken into account*/; i < contract.versions.length; i++) {
    const version = contract.versions[i]
    const previousApplicableQuote = applicableQuotes[applicableQuotes.length - 1]
    const quote = buildQuoteInvoice(version, organizationType)
    if (deepEqual(quote, previousApplicableQuote.quote)) {
      // if two consecutive endorsements use the same quote, no need to ask the to display create confusion
      // be displaying two identical quotes
      continue
    }

    const startTimestamp = version.startTimestamp
    previousApplicableQuote.endTimestamp = startTimestamp
    applicableQuotes.push({
      id: applicableQuotes.length.toFixed(0),
      startTimestamp,
      endTimestamp: null,
      quote,
    })
  }
  return applicableQuotes
}
